<template>
  <div class="home">
    <v-layout row wrap class="d-flex justify-center">
      <v-flex xs12 md12 lg12 class="mb-5">
        <h1 class="f-alfa primary--text">
          <router-link to="/">Pro Track</router-link> / Tasks
        </h1>
      </v-flex>
      <v-flex xs12 class="mt-5">
        <div class="text-right">
            <v-btn class="white--text primary" @click="addTask" rounded><v-icon class="mr-2">mdi-plus</v-icon>Add Task</v-btn>    
        </div>
        
        <v-layout row wrap v-if="tasks.length>0">
          <v-flex
            xs12
            v-for="item in tasks"
            :key="item._id"
            class="grey darken-4 pa-5 mt-5"
            style="border-radius: 10px"
          >
            <h3 class="primary--text">{{ item.title }}</h3>
            <p class="caption accent--text">{{ item.created }}</p>
            <p>{{ item.description }}</p>
            <v-layout row wrap>
              <v-flex xs12 lg4 class="mt-2 mb-2">
                <div class="d-flex">
                  <img
                    :src="item.assigned_to.display_picture"
                    alt=""
                    style="width: 48px; height: 48px; border-radius: 24px"
                    v-if="item.assigned_to.display_picture"
                  />
                  <div
                    style="
                      width: 48px;
                      height: 48px;
                      background-color: #212121;
                      border-radius: 24px;
                    "
                    v-else
                  ></div>
                  <div class="pl-3">
                    <p class="pa-0 ma-0">{{ item.assigned_to.name }}</p>
                    <p class="pa-0 ma-0 accent--text">
                      {{ item.assigned_to.department.name }}
                    </p>
                  </div>
                </div>
              </v-flex>
              <v-flex xs12 lg6 class="mt-2 mb-2">
                <h4>
                  <span class="accent--text">PROJECT : </span>
                  <span v-if="item.project">{{ item.project.name }}</span> <span v-else>N/A</span>
                </h4>
                <h4>
                  <span class="accent--text">DEADLINE : </span>
                  {{ item.end_date }}, {{ item.end_time }}
                </h4>
              </v-flex>

              <v-flex xs12 lg2 class="mt-2 mb-2">
                <h4>
                  <span class="accent--text">STATUS : </span>
                  {{ item.status }}
                </h4>
                <h4>
                  <span class="accent--text">COMPLETION : </span>
                  {{ item.completion }}%
                </h4>
              </v-flex>
            </v-layout>
          </v-flex>
        </v-layout>
        <v-layout v-else>
          <v-flex xs12>
            <p>No Available Tasks</p>
          </v-flex>
        </v-layout>
      </v-flex>
    </v-layout>
  </div>
</template>

<script>
import Axios from "axios";
import { BASE_URL } from "../../config";
import { mapGetters } from "vuex";
export default {
  data() {
    return {
      tasks: [],
    };
  },
  mounted() {
    this.fetchTasks();
  },
  computed: { ...mapGetters(["USER"]) },
  methods: {
    async fetchTasks() {
      const headers = {
        Authorization: this.USER.token,
      };
      let url = BASE_URL + "/task";
      let { data } = await Axios.get(url,{headers});
      this.tasks = data;
      console.log(JSON.stringify(data));
    },
    addTask(){
        this.$router.push('/add-task')
    }
  },
};
</script>